/*#region navbar style */

.main-navbar-container {
    background-color: #ffffff;
}

.main-navbar {
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    .uk-navbar-nav > li > a,
    .uk-navbar-item,
    .uk-navbar-toggle {
        min-height: 60px;
    }
}

/*#endregion */
