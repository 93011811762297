﻿@font-face {
    font-family: 'Lora';
    src: url('/fonts/Lora-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Lora';
    src: url('/fonts/Lora-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-RegularItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}